.wrap {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  font-size: 1.4rem;
  /* border: 1.5px solid hsla(var(--main-hue), 20%, 90%, 0.5); */
  overflow: hidden;
}

.filler1 {
  position: absolute;
  background: hsla(var(--bg-hue), 5%, 90%, 0.2);
  height: 100%;
  transition: width 1s linear;
  transition-duration: 0.1s;
}

.content {
  padding: 0.4rem 0.6rem;
}
