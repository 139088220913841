.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: grid;
  justify-items: center;
  align-items: center;
}

.message {
  user-select: none;
  font-size: 1.8rem;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 1rem;
  background: hsl(var(--bg-hue), 40%, 20%);
  background: linear-gradient(
    to bottom,
    hsl(var(--bg-hue), 80%, 40%) 0%,
    hsl(var(--bg-hue), 40%, 10%) 50%,
    hsl(var(--bg-hue), 80%, 30%) 100%
  );
  padding: 1rem;
  margin: 0.6rem;
  text-align: center;
}

.message > p {
  padding: 0;
  margin: 0.2rem 0;
}
