.questionInfo {
  padding-top: 0.3rem;
  background: hsla(var(--bg-hue), 40%, 30%, 0.2);
  text-align: center;
  font-size: 1.3rem;
}

.question {
  background: hsla(var(--bg-hue), 80%, 5%, 0.2);
  text-align: center;
  padding: 0.3rem 0.5rem;
  font-size: 1.5rem;
}

.votePercents {
  display: inline-block;
  font-size: 1rem;
  text-align: right;
  width: 2rem;
  margin-right: 0.5rem;
  color: hsla(var(--main-hue), 10%, 90%, 0.6);
}

.questionWrap {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
}

.answers {
  overflow: auto;
}
