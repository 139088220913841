.wrap {
  position: relative;
  background: hsl(var(--bg-hue), 40%, 25%);

  overflow: hidden;
  height: 3.5rem;
}

.slider {
  position: absolute;
  left: calc(50% - 3.2rem);
  top: 0;
  display: flex;
}

.entry {
  color: var(--text-color);
  margin: 0.5rem 0.2rem;
  padding: 0.5rem;
  width: 6rem;
  height: 2.5rem;
  text-align: center;
  background: rgba(168, 164, 160, 0.2);
}

.selected {
  position: absolute;
  left: calc(50% - 3.2rem);
  top: 0.2rem;
  bottom: 0.2rem;
  width: 6.4rem;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 0.4rem;
}

.stopped {
  left: calc(50% - 5rem);
  width: 10rem;
  overflow: hidden;
  transition: width 0.2s, left 0.2s;
}

.selectedEntry {
  margin: 0;
  padding: 0.2rem 0.1rem;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgb(52, 88, 121);
  animation: selectAnim 0.2s;
  animation-fill-mode: forwards;
}

@keyframes selectAnim {
  from {
    padding-top: 0.7rem;
  }
  to {
    font-size: 1.6rem;
    padding-top: 0.2rem;
  }
}
