.wrap {
    text-align: center;
    overflow: auto;
}

.table {
    width: 100%;
    border: none;
    border-collapse: collapse;
}

.tr {
    font-size: 1.5rem;
    height: 2.8rem;
}

.tr:nth-child(even) {
    background: rgba(255, 255, 255, 0.15);
}

tr.me {
    background: rgba(47, 39, 128, 0.4);
    color: rgb(255, 209, 110);
    font-weight: 600;
}

td.username {
    text-align: left;
}

td.score {
    text-align: right;
    padding-right: 0.5rem;
}

td.rank {
    text-align: right;
    width: 3rem;
    padding-right: 0.5rem;
}