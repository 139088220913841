.container {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.userInfo {
  background: hsla(var(--main-hue), 40%, 30%, 0.7);
  padding: 0.5rem;
  font-size: 1.5rem;
  box-shadow: 0 0.3rem 0.15rem 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.username {
  flex: 1;
  text-align: center;
}

.qr {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40vmin;
  height: 40vmin;
  opacity: 0.6;
  padding: 2vmin;
  background: #FFF;
}
