.wrap {
    background: rgba(34, 29, 29, 0.4);
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;


}

.wrap > * {
    width: 100%;
    height: 100%;
    object-fit: contain;
}