.answer {
  position: relative;
  border-radius: 1.4rem;
  background: rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  font-size: 1.5rem;
  border: 1.5px solid hsla(var(--main-hue), 20%, 90%, 0.5);
  margin: 1rem 0.4rem;
  overflow: hidden;
}

.answer:hover {
  background: hsla(var(--main-hue), 50%, 35%, 0.3);
}

.filler {
  position: absolute;
  background: hsla(var(--bg-hue), 5%, 90%, 0.2);
  height: 100%;
  transition: width .1s ease-in;
}

.content {
  padding: 0.4rem 0.6rem;
}

.selected, .selected:hover {
  background-color: hsla(40, 90%, 40%, 0.6);
}

.correct, .correct:hover {
  background-color: hsla(120, 90%, 40%, 0.6);
}

.wrong, .wrong:hover {
  background-color: hsla(0, 90%, 40%, 0.6);
}
