.header {
  text-align: center;
  font-size: 1.5rem;
  padding: 0.6rem 0;
  margin-bottom: 1rem;
  background: rgba(0, 0, 0, 0.226);
}

.content {
  text-align: center;
}

.button {
  border-radius: 1.5rem;
  border: 2px solid rgb(212, 212, 212);
  padding: 0.3rem 0.8rem;
  font-size: 1.4rem;
  margin: 1.5rem 0.4rem;
  background: rgba(36, 50, 59, 0.5);
  color: white;
}

.button:focus, .input:focus {
  outline: none;
}

.input {
  border-radius: 0.8rem;
  border: 2px solid rgb(107, 107, 107);
  font-size: 1.4rem;
  padding: 0.3rem 0.8rem;
  margin: 0.4rem 0;
}

.error {
    color: rgb(243, 124, 116);
}