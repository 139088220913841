html {
  --main-hue: 210;
  --bg-hue: 205;
  --text-color: hsl(var(--main-hue), 10%, 95%);
}

.App {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  background: hsl(var(--bg-hue), 40%, 30%);
  background: linear-gradient(
    to bottom,
    hsl(var(--bg-hue), 80%, 30%) 0%,
    hsl(var(--bg-hue), 40%, 10%) 50%,
    hsl(var(--bg-hue), 80%, 20%) 100%
  );

  color: var(--text-color);
}
